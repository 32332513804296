<template>
  <div class="main">
    <BigTitleBner title="INTRODUCTION" :url="`${this.$imgUrl}img_a_1.png`" />
    <div class="main-con">
      <div class="containerView">
        <div class="article-title text_center text_bold">
          <h2 class="size_48">INTRODUCTION</h2>
          <h3 class="size_24">{{ $t("abstractText.text1") }}</h3>
        </div>
        <div class="article-con size_20">
          <article>
            <p
              v-animate="'animated fadeInUp'"
              v-html="$t('abstractText.text2')"
            ></p>
            <div class="view_flex" v-animate="'animated fadeInUp'">
              <p>
                <img
                  class="illustration_1"
                  :src="`${this.$imgUrl}img_a_2.png`"
                />
                <span v-html="$t('abstractText.text3')"></span>
              </p>
            </div>
            <div style="clear: both"></div>
            <p
              v-html="$t('abstractText.text4')"
              v-animate="'animated fadeInUp'"
            ></p>
            <div class="illustration-box view_flex_c">
              <div class="illustration_left" v-animate="'animated fadeInUp'">
                <img :src="`${this.$imgUrl}img_a_3.png`" />
              </div>
              <div class="illustration_right view_flex_csb">
                <div v-animate="'animated fadeInUp'">
                  <img :src="`${this.$imgUrl}img_a_4.png`" />
                </div>
                <div v-animate="'animated fadeInUp'">
                  <img :src="`${this.$imgUrl}img_a_5.png`" />
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigTitleBner from "@/components/BigTitleBner";
export default {
  name: "abstract",
  data() {
    return {};
  },
  components: {
    BigTitleBner,
  },
};
</script>
<style lang="scss" scoped>
.main-con {
  padding-top: 100px;
  padding-bottom: 100px;
  .containerView {
    width: 1059px;
  }
}

.illustration_1 {
  width: 427px;
  height: 443px;
  background: #eee;
  margin-right: 46px;
  float: left;
}

.article-title {
  margin-bottom: 50px;
  > h2 {
    margin-bottom: 10px;
  }
}

.article-con {
  > article {
    p {
      text-align: justify !important;
    }
    > p {
      margin-bottom: 50px;
    }
    > div {
      margin-bottom: 50px;
    }
  }
}

.illustration-box {
  height: 590px;
  margin: 0;
}

.illustration_left {
  width: 714px;
  height: 100%;
  margin-right: 28px;
  background: #eee;
}

.illustration_right {
  flex: 1;
  height: 100%;
  width: 0;
  > div {
    height: 289px;
    background: #eee;
  }
}
</style>
